body[data-sidebar="dark"].vertical-collpsed {
    min-height: 100vh;
}

.btn-purple {
    color: #fff !important;
    background-color: #5c0385 !important;
}

.btn-red {
    color: #fff !important;
    background-color: #bf1e30 !important;
}

.btn {
    text-transform: uppercase;
}

.bg-purple {
    color: #fff;
    background-color: #5c0385;
}

.border-bottom {
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
}

.icon-center {
    display: inline-block;
    transform: translateY(2px);
}

.input-span {
    padding: 0.47rem 0.75rem;
    border-radius: 2px;
    font-size: 14px;
    border: 1px solid var(--bs-input-border-color);
    background-color: var(--bs-gray-200);
    color: var(--bs-gray-700);
    overflow-x: scroll;
    scrollbar-width: none;
}

.table {
    vertical-align: middle;
}

.table> :not(caption)>*>* {
    padding: 8px ;
}

.action-icon {
    padding: 2px 6px;
    font-size: 11px;
}

.sidebar-link {
    color: #111 !important;
    font-weight: 500;
}

.sidebar-link i {
    color: #000 !important;
}

.sidebar-link:hover {
    background-color: #5c0385 !important;
}

.sidebar-link:hover i {
    color: #fff !important;
}

.sidebar-link:hover {
    color: #fff !important;
}

.bg-header {
    background-color: #330148 !important;
}

.bg-sidebar {
    background-color: #d0b9f9 !important;
}

.custom-file-upload {
    /* background-color: #F3F6F9; */
    background-color: #ede3ff;
    border-radius: 5px;
    cursor: pointer;
}

#side-menu .active {
    background-color: #5c0385 !important;
    color: white;
}

.rdw-editor-main {
    height: 100px;
}
.select__menu{
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    z-index: 9999;
    position: relative;
}
.ck-content{
    height: 100px !important;
    overflow-y: scroll;
    scrollbar-width: thin;
}
.ck-icon{
    z-index: 0 !important;
}
@media screen and (min-width: 320px) and (max-width: 700px) {
    .table> :not(caption)>*>* {
        padding: 11px ;
    }
}