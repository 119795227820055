.border-left {
  border-left: 3px solid var(--bs-gray-400) !important;
}

.userImg {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px;
  border: 3px solid lightgray;
  position: relative;
  right: 20%;
}

.notes-box {
  margin-left: 20px !important;
  padding-top: 0 !important;
}

.note {
  background-color: #f3f6f9;
  position: relative;
}

.note-icon {
  color: #f3f6f9;
  position: absolute;
  top: 10%;
  left: -1.1%;
  transform: rotate(-90deg);
}
.add-reply-images {
  background-color: white;
  margin-right: 8px;
  padding: 2px;
}
.add-reply-images img {
  height: 25px;
  width: 30px;
  margin-right: 5px;
  cursor: pointer;
}
.vertical-collpsed .vertical-menu {
  position: fixed !important;
}
.width-id {
  width: 5.5%;
  text-align: center;
}
.width-action {
  width: 10%;
  text-align: center;
}
.date_width {
  width: 11.5%;
  text-align: center;
}
.bd-pass-icon {
  position: absolute;
  top: 26%;
  right: 11px;
}
.view-ticket-detail img {
  height: 55px;
  width: 80px;
  margin-right: 20px;
  cursor: pointer;
}
.sidenav {
  width: 0;
  position: fixed;
  z-index: 9998;
  top: 21.2%;
  right: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.bd-description-sect {
  padding: 20px;
  background-color: #f3f6f9;
  border-radius: 3px;
  border: 1px solid #dbdde0;
}
.bd-description-sect1 {
  padding: 20px !important;
  background-color: #f3f6f9;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.text-black {
  color: black;
  font-weight: 600;
}
.bd-status span {
  font-size: 14px;
}
.priority-width {
  width: 8.5%;
  text-align: center;
}
.status-width {
  width: 8.5%;
  text-align: center;
}

.font-size-12-5 {
  font-size: 12.5px !important;
}
.bd-dash-users {
  color: white;
  font-size: 36px;
  padding: 5px;
  background-color: #989898;
  border-radius: 8px;
}
.bd-descriptive-side {
  width: 90%;
}
.action-side {
  width: 10%;
}
[data-rmiz-modal-overlay="visible"] {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.bd-shadow-ticket {
  background-color: #efe8fa;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 7px;
}
.padding-10 {
  padding: 10px;
}
.width-reply {
  width: 80%;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left:40%;
  width:110px;
  top:42px
}
.dropdown-content a {
  color: black !important;
  padding: 5px 16px;
  text-decoration: none;
  display: block;

}
.dropdown-edit-delete:hover .dropdown-content {
  display: block;
}
.bd-custom-maring{
  margin-top:57px;
}
.bd-home-text{
  font-size: 23px;
}
.bd-logo-login{
  width:100%
}
@media screen and (min-width: 320px) and (max-width: 700px) {
  .display-flex-column {
    flex-wrap: wrap;
  }
  .bd-logo-login{
    height: 60px;
    width: auto;
    margin-bottom:30px
  }
  .auth-logo{
    text-align: center;
  }
  .bd-custom-maring{
    margin-top:0px;
    margin-bottom: -20px !important;
  }
  .bd-home-text{
    font-size: 18px;
  }
  .width-reply {
    width: 100%;
  }
  .responsive-right {
    justify-content: start !important;
  }
  .resp-mt-10 {
    margin-top: 10px !important;
  }
  .resp-mr-10 {
    margin-right: 10px !important;
  }
  .resp-mb-20 {
    margin-bottom: 20px !important;
  }
  .resp-mt-20 {
    margin-top: 20px !important;
  }
  .bd-resp-margin {
    margin-bottom: 10px;
  }
  .mobile-align-iten-center {
    justify-content: center;
  }
  .no-margin {
    margin-left: 3px;
  }
  .mobile-mt-20 {
    margin-top: 20px;
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  .responsive-main-table {
    margin-top: 15px;
  }

  .no-data {
    padding-left: 0px !important;
  }
  .bd-status {
    display: none;
  }
  .mobile-col-12 {
    width: 100%;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr:nth-child(1) {
    border-style: none !important;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee !important;
    position: relative;
    padding-left: 45% !important;
    /* padding-top:8px !important;
    padding-bottom:8px !important */
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 12px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);
    color: var(--bs-table-color);
    font-weight: bold;
  }
  .width-id,
  .status-width,
  .priority-width,
  .date_width,
  .width-action {
    width: 100%;
    text-align: left !important;
  }
  .bd-responsivewidth {
    width: 50%;
    text-align: left !important;
  }
  .bd-custom-sidebar {
    padding: 2px 7px !important;
  }
  .bd-custom-sidebar i {
    font-size: 10px;
  }
  .bd-responsive-filter {
    width: 0px;
  }
  .bd-reposive-none {
    display: none !important;
  }
  .display-none {
    display: none;
  }
  .mobile-ml-0 {
    margin-left: 0px !important;
  }
  .mobile-w-100 {
    width: 100% !important;
  }
  .mobile-icon-view {
    width: 22px !important;
    height: 22px !important;
  }
  .reply-padding-0 {
    padding-left: 3px !important;
  }
  .action-side {
    display: none;
  }
  .verti-timeline {
    margin-left: 5px !important;
  }
  .note-icon {
    top: 4%;
    left: -5.1%;
  }
  .userImg {
    right: 10%;
  }
}

@media screen and (min-width: 700px) {
  .bd-custom-sidebar {
    display: none !important;
  }
  .bd-web-none {
    display: none !important;
  }
}
.text-white-login {
  color: white !important;
}
